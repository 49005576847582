import React from "react"
import SEO from "../../components/seo"
import HomePageLayout from "../../components/layouts/HomePageLayout"
import classes from "./index.module.css"
import { HeaderVariants } from "../../components/header"
import { Link } from "gatsby"
import header from "../../images/uidesigner-header.jpg"
import uxreasonsheader from "../../images/reasons.png"
import dishaHeader from "../../images/dishaVSoleads.png"

const Index = () => (
  <HomePageLayout headerVariant={HeaderVariants.dark} pageTitle="Blog">
    <SEO title="Blog" />
    <div className={classes.container}>
      <div className={classes.blogPost}>
        <div className={classes.blogPostDetails}>
          <Link className={classes.blogPostHeader} to="/blog/disha-vs-oleads">
            Disha versus Oleads – What we think!
          </Link>
          <p className={classes.blogPostDescription}>
            We live in a world where every individual and organization is
            embarking on some sort of digital transformation...
          </p>
          <p className={classes.blogPostAuthor}>Titilola Opawuyi</p>
          <p className={classes.blogPostDate}>Nov 12, 2019</p>
        </div>
        <img src={dishaHeader} className={classes.blogPostImage} alt="" />
      </div>
      <span className={classes.splitLine} />
      <div className={classes.blogPost}>
        <div className={classes.blogPostDetails}>
          <Link
            className={classes.blogPostHeader}
            to="/blog/skills-for-ui-designers"
          >
            What skills do you need to be a UI/UX designer
          </Link>
          <p className={classes.blogPostDescription}>
            Ideally, a UI/UX Designer should have some certain skill set to be
            considered a good designer. People call themselves designers without
            really understanding the details of good design...
          </p>
          <p className={classes.blogPostAuthor}>Tiwa Ijaware</p>
          <p className={classes.blogPostDate}>Nov 15, 2019</p>
        </div>
        <img src={header} className={classes.blogPostImage} alt="" />
      </div>
      <span className={classes.splitLine} />
      <div className={classes.blogPost}>
        <div className={classes.blogPostDetails}>
          <Link
            className={classes.blogPostHeader}
            to="/blog/reasons-to-invest-in-ux"
          >
            4 Reasons to Invest in UX Design
          </Link>
          <p className={classes.blogPostDescription}>
            There are still components of uncertainty around the financial
            returns of user experience design. This is probably...
          </p>
          <p className={classes.blogPostAuthor}>Segun Micheal Oroyo</p>
          <p className={classes.blogPostDate}>Nov 15, 2019</p>
        </div>
        <img src={uxreasonsheader} className={classes.blogPostImage} alt="" />
      </div>
    </div>
  </HomePageLayout>
)

export default Index
